import React, { useState, useEffect, isValidElement } from "react";
import { Button, Message, Form, Input } from "semantic-ui-react";
import api from "../../store/api";
import { useUser } from "../session/hooks";

import { Redirect, useParams } from "react-router-dom";

import "./prospectForm.css";
import TrInput from "../trInput/trInput";

const ProspectForm = () => {
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [error, setError] = useState(false);

  const [errors, setErrors] = useState([]);
  const [value, setValue] = useState({ type: "not hiring" });

  const [isDomainValid, setIsDomainValid] = useState();
  const [validationMessage, setValidationMessage] = useState();

  const onChange = (e) => {
    const newState = { ...value };
    newState[e.target.name] = e.target.value;

    if (e.target.name === "companyDomain") setIsDomainValid(null);
    setValue(newState);
  };

  const validateDomain = async () => {
    if (!value.companyDomain || value.companyDomain === "") {
      return;
    }
    setValidationMessage("Checking domain...");

    const qs = {
      domain: value.companyDomain,
      prospecter: user.name,
    };

    const result = await api.get("prospecting/check-prospect", { params: qs });

    setIsDomainValid(result.data.total === 0);
    setValidationMessage(null);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // debugger;
    const required = [
      "firstName",
      "company",
      "companyDomain",
      "email",
      "position",
      "linkedin",
      "source",
      "sourceUrl",
      "type",
    ];

    if (value.type !== "not hiring") required.push("opening");

    const missing = required.filter((f) => !value[f] || value[f] === "");

    if (missing.length > 0) {
      setErrors(missing);
      return;
    }

    value.prospecter = user.name;

    try {
      setLoading(true);
      await api.post("prospecting/prospects", value);

      setSuccess(true);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <div>
        <h2>Error!...</h2>
        <p>{JSON.stringify(error.response.data)}</p>
      </div>
    );
  }

  if (loading) {
    return (
      <section style={{ textAlign: "center" }}>
        <div>
          <img src="/img/blocks.gif" alt="southteams" style={{ width: 80 }} />
        </div>
      </section>
    );
  }

  if (success) {
    return (
      <section
        style={{ textAlign: "center" }}
        onKeyPress={(e) => alert(JSON.stringify(e))}
      >
        <div>
          <h1>Prospect sent!</h1>
          <p>
            Click&nbsp;
            <Button
              className="ui button green"
              onClick={() => window.location.reload()}
            >
              Here
            </Button>{" "}
            to send a new one!
          </p>
        </div>
      </section>
    );
  }

  return (
    <React.Fragment>
      <h2>Let's add a new prospect!</h2>

      <Form onSubmit={onSubmit} autoComplete="off">
        <h3>Company Information</h3>
        <table style={{ width: "100%" }}>
          <TrInput
            name="company"
            placeholder="Company name, for example ACME..."
            label="Company Name"
            onChange={onChange}
            errors={errors}
            autoFocus
          />

          <TrInput
            name="companyDomain"
            placeholder="Company domain, for example acme.com..."
            label="Company Domain"
            onChange={onChange}
            errors={errors}
            onBlur={validateDomain}
          />
        </table>

        {isDomainValid && (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              border: "0px !important",
              padding: 10,
            }}
          >
            <label style={{ color: "green" }}>
              Awesome! Domain <code>{value.companyDomain}</code> is valid! Go
              ahead!
            </label>
          </div>
        )}

        {isDomainValid === false && (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              border: "0px !important",
              padding: 10,
            }}
          >
            <label style={{ color: "red" }}>
              Bad news!! Domain <code>{value.companyDomain}</code> was already
              found!
            </label>
          </div>
        )}

        {isDomainValid === null && validationMessage && (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              border: "0px !important",
              padding: 10,
            }}
          >
            <label>{validationMessage}</label>
          </div>
        )}

        <h3>Contact Information</h3>

        <table style={{ width: "100%" }}>
          <TrInput
            name="firstName"
            placeholder="Full name, for example Segio Denis..."
            label="Full Name"
            onChange={onChange}
            errors={errors}
          />

          <TrInput
            name="email"
            placeholder="Email address..."
            label="Email"
            onChange={onChange}
            errors={errors}
            type="email"
          />

          <TrInput
            name="linkedin"
            placeholder="Linkedin Url, for example: https://linkedin.com/in/sergio_denis..."
            label="LinkedIn"
            onChange={onChange}
            errors={errors}
            type="url"
          />

          <TrInput
            name="position"
            placeholder="Position, for example: VP of Songs..."
            label="Position"
            onChange={onChange}
            errors={errors}
          />
        </table>

        <h3>Prospect Information</h3>

        <table style={{ width: "100%" }}>
          <TrInput
            name="source"
            placeholder="Source, for example 'indeed' or 'your website'..."
            label="Source"
            onChange={onChange}
            errors={errors}
          />

          <TrInput
            name="sourceUrl"
            placeholder="Source Link for example 'https://indeed.com/jobs/post/1'..."
            label="Source Link"
            onChange={onChange}
            errors={errors}
            type="url"
          />

          <tr>
            <th>Hiring Status </th>
            <td>
              <select name="type" placeholder="Type..." onChange={onChange}>
                <option value="hiring remote">1 - Hiring Remote</option>
                <option value="hiring remote USA">2 - Hiring Remote USA</option>
                <option value="hiring on site">3 - Hiring OnSite</option>
                <option value="not hiring" selected>
                  4 - Not Hiring
                </option>
              </select>
            </td>
          </tr>

          {value.type !== "not hiring" && (
            <TrInput
              name="opening"
              placeholder="Opening, for example 'Sr. React.js Engieer'..."
              label="Opening"
              onChange={onChange}
              errors={errors}
            />
          )}

          <tr>
            <th style={{ verticalAlign: 'top'}}>More info</th>
            <td>
              <textarea
                name="info"
                onChange={onChange}
                errors={errors}
                placeholder="Add some relevant notes."
              >
              </textarea>
            </td>
          </tr>
        </table>

        <div style={{ textAlign: "center", margin: 50 }}>
          <button
            className="ui button primary"
            type="submit"
            style={{ fontSize: 20 }}
            disabled={!isDomainValid}
          >
            Send Prospect!
          </button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default ProspectForm;
