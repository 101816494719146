import React from "react";
import { Menu, Dropdown } from "semantic-ui-react";
import "./navMenu.css";
import { useUser, useSessionActions } from "../session/hooks";

const NavMenu = () => {
  const user = useUser();
  const { logOut } = useSessionActions();

  return (
    <Menu borderless size="small" className="navMenu">
      <Menu.Item href="/">
        <img src="/img/48.png" alt="Southteams" style={{ marginLeft: 20 }} />
      </Menu.Item>

      <Dropdown item text={user.name} className="right">
        <Dropdown.Menu>
          <Menu.Item as="a" href="/berlin-chrome-ext.zip">
            Download Chrome Extension
          </Menu.Item>
          <Menu.Item name="logout" onClick={logOut}>
            Log Out
          </Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
};

export default NavMenu;
